<template>
  <div class="container">
    <div class="top-box flex align-center">
      <div class="title">维修订单<span class="tip">({{type_index==1?'鼠标移至“故障描述”栏查看完整描述':'鼠标移至“姓名”栏即可查看发货地址'}})</span></div>
      <div class="btn-group flex">
        <span class="list border-right" :class="type_index==1?'active':''" @click="type_switch(1)">待报价</span>
        <span class="list border-right" :class="type_index==3?'active':''" @click="type_switch(3)">待收货</span>
        <span class="list border-right" :class="type_index==4?'active':''" @click="type_switch(4)">待寄回</span>
        <span class="list" :class="type_index==5?'active':''" @click="type_switch(5)">维修完成</span>
      </div>
      <div class="refresh" @click="type_switch(type_index,true)"><i class="el-icon-refresh"></i></div>
    </div>
    <el-table v-if="tableData!=null" stripe :data="tableData.filter(data => !search || data.name.includes(search)|| data.number.includes(search)||data.phone.includes(search)||data.uniqid.includes(search)||data.location.includes(search))" style="width: 100%">
      <el-table-column label="订单编号" prop="uniqid"></el-table-column>
      <el-table-column label="姓名" prop="name" v-if="type_index!=1">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p><span class="item-name">姓名: </span>{{ scope.row.name }}</p>
            <p><span class="item-name">发货地址: </span>{{ scope.row.location }}</p>
            <div slot="reference">
              {{ scope.row.name }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="phone" v-if="type_index!=1"></el-table-column>
      <el-table-column label="快递编号" prop="number" v-if="type_index!=1"></el-table-column>
      <el-table-column label="故障描述" prop="bad_desc" v-if="type_index==1">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p><span class="item-name">订单编号: </span>{{ scope.row.uniqid }}</p>
            <p><span class="item-name">故障描述: </span>{{ scope.row.bad_desc }}</p>
            <div slot="reference">
              {{ scope.row.bad_desc }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="下单时间" prop="time"></el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="{}">
          <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
        </template>
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="img_overview(scope.$index)" v-if="type_index==1">故障图片</el-button>
          <el-button size="mini" type="danger" @click="give_price(scope.row.uniqid)" v-if="type_index==1">报价</el-button>
          <el-button size="mini" type="danger" @click="iget_it(scope.row.uniqid)" v-if="type_index==3">确认收货</el-button>
          <el-button size="mini" type="success" @click="return_back(scope.row.uniqid)" v-if="type_index==4">寄回</el-button>
          <el-button size="mini" type="success" @click="back_info(scope.$index)" v-if="type_index==5">寄回信息</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog class="img-dialog" title="故障图片" :visible.sync="img_dialog" width="90%">
      <div class="img-container" v-for="item in img_arr" :key="item">
        <img :src="item" />
      </div>
    </el-dialog>

    <el-dialog title="维修报价" :visible.sync="open_dialog" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="维修价格" label-width="120px">
          <el-input type="number" v-model="form.price" placeholder="请输入维修报价" autocomplete="off" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="故障定位" label-width="120px">
          <el-input type="textarea" v-model="form.desc" autocomplete="off" placeholder="请输入故障定位描述" maxlength="100"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="open_dialog=false">取 消</el-button>
        <el-button size="medium" type="primary" @click="give">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="邮寄信息" :visible.sync="kuaidi_dialog" :close-on-click-modal="false" @closed="kuaidi_dialog_close">
      <el-form :model="back_form">
        <el-form-item label="快递编号" label-width="120px">
          <el-input type="text" v-model="back_form.back_number" placeholder="请输入您已寄出的快递编号" autocomplete="off" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="邮寄人姓名" label-width="120px">
          <el-input type="text" v-model="back_form.back_name" placeholder="请输入快递发货人的姓名" autocomplete="off" maxlength="10"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="kuaidi_dialog=false">取 消</el-button>
        <el-button size="medium" type="primary" @click="back">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog class="detail-dialog" title="寄回信息" :visible.sync="detail.dialog">
      <div class="list"><strong>发货人姓名：</strong>{{detail.back_name}}</div>
      <div class="list"><strong>快递编号：</strong>{{detail.back_number}}</div>
    </el-dialog>
  </div>
</template>

<script>
import { msg,loading,confirm,throttle } from '../assets/js/util';

export default {
  data() {
    return {
      type_index: 0,
      tableData: null,
      search: '',
      form: {},
      back_form: {},
      open_dialog: false,
      kuaidi_dialog: false,
      img_dialog: false,
      img_arr: [],
      detail: {
        dialog: false,
      },
    }
  },
  methods: {
    //订单类型切换
    type_switch(index,refresh=false) {
      if(index==this.type_index&&!refresh) return;
      this.type_index=index;
      this.tableData=null;
      const load=loading();
      this.$axios.post('index/repair/render',{ type: index }).then(res => {
        load.close(); 2
        this.tableData=res.data.orders;
        this.$parent.badges[2]=0;
      })
    },

    //查看故障图片
    img_overview: throttle(function(index) {
      this.img_dialog=true;
      this.img_arr=this.tableData[index].imgs;
    }),

    //确认收货
    iget_it(id) {
      confirm('提示','确定已经收到用户邮寄的物品？').then(() => {
        const load=loading();
        this.$axios.post('/index/repair/update_order',{ uniqid: id,status: 4 }).then(res => {
          load.close();
          const { data }=res;
          if(data.code==1) {
            this.tableData=this.tableData.filter(e => e.uniqid!=id);
            msg('已确认收货');
          }
        })
      }).catch(() => { })
    },

    //报价
    give_price(id) {
      this.form.uniqid=id;
      this.open_dialog=true;
    },
    give() {
      const { form }=this;
      if(!form.price) return msg('请输入维修报价','error');
      if(!form.desc) return msg('请输入故障定位描述','error');
      form.status=2;

      confirm('提示','此报价不可修改，请谨慎操作，确认继续？').then(() => {
        const load=loading();
        this.$axios.post('/index/repair/update_order',form).then(res => {
          load.close();
          const { data }=res;
          if(data.code==1) {
            this.form={};
            this.open_dialog=false;
            this.tableData=this.tableData.filter(e => e.uniqid!=form.uniqid);
            msg('报价成功');
          }
        })
      }).catch(() => { })
    },

    //寄回
    return_back(id) {
      this.back_form.uniqid=id;
      this.kuaidi_dialog=true;
    },
    back() {
      const { back_form }=this;
      if(!back_form.back_number) return msg('请输入您已寄出的快递编号');
      if(!back_form.back_name) return msg('请输入快递发货人的姓名');
      back_form.status=5;

      confirm('提示','邮寄信息不可修改，请保证输入无误！确认继续？').then(() => {
        const load=loading();
        this.$axios.post('/index/repair/update_order',back_form).then(res => {
          load.close();
          const { data }=res;
          if(data.code==1) {
            this.back_form={};
            this.kuaidi_dialog=false;
            this.tableData=this.tableData.filter(e => e.uniqid!=back_form.uniqid);
            msg('寄回成功');
          }
        })
      }).catch(() => { })
    },
    kuaidi_dialog_close() {
      this.back_form={};
    },

    //寄回信息
    back_info(index) {
      const { back_name,back_number }=this.tableData[index];
      this.detail={ dialog: true,back_name,back_number };
    }
  },

  created() {
    this.type_switch(1);
  }
}
</script>

<style scoped>
.container {
  padding: 0 10px 100px 10px;
}

::v-deep .el-input__inner {
  max-width: 260px;
  padding: 17px 12px;
}
::v-deep .el-textarea__inner {
  max-width: 360px;
  padding: 10px 12px;
}
::v-deep .el-dialog {
  max-width: 600px;
  width: auto;
}

::v-deep .img-dialog .el-dialog {
  max-width: unset;
  height: 90vh;
  margin: 5vh auto !important;
  overflow-y: auto;
}

::v-deep .el-dialog__body {
  padding-bottom: 10px;
}

::v-deep .el-table td.el-table__cell div {
  white-space: nowrap;
  overflow: hidden;
  padding-right: 2.5vw;
}

::v-deep .el-popover__reference {
  text-overflow: ellipsis;
}

::v-deep .img-dialog .el-dialog__body {
  padding: 0;
}

::v-deep .detail-dialog .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .detail-dialog .list {
  font-size: 13px;
  letter-spacing: 0.8px;
  padding-bottom: 10px;
}

.top-box .title {
  font-size: 16px;
  color: #393d49;
  font-weight: bold;
  letter-spacing: 1px;
}

.top-box .title .tip {
  font-size: 12px;
  color: #9e9e9e;
  font-weight: normal;
  letter-spacing: 0.3px;
}

.top-box .btn-group {
  border: 1px solid #393d49;
  border-radius: 5px;
  margin-left: 30px;
}

.top-box .btn-group .list {
  display: inline-block;
  font-size: 12px;
  color: #393d49;
  padding: 5px 10px;
  transition: all 0.2s;
  cursor: pointer;
}

.top-box .btn-group .list.border-right {
  border-right: 1px solid #393d49;
}

.top-box .btn-group .list.active {
  color: #fff;
  background: #393d49;
}

.top-box .refresh {
  color: #fff;
  padding: 3px 6px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 30px;
  border-radius: 3px;
  background: #393d49;
}

.img-dialog .img-container {
  text-align: center;
  padding: 10px;
  overflow: hidden;
}

.img-dialog .img-container img {
  max-width: 100%;
}
</style>