<!--
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2022-02-20 14:11:19
-->
<template>
  <div class="container" v-if="imgs!=null">
    <div class="title">首页顶部banner</div>
    <div class="flex wrap">
      <div class="list flex align-center" v-for="item in 6" :key="item">
        <img-uploader class="big" ref="img_uploader" @selected="imgs[item-1]=$event" :img="imgs[item-1]"></img-uploader>
        <div class="btn-container">
          <el-button type="danger" @click="remove(item,imgs[item-1])" v-if="item!=1">移除</el-button>
          <el-button type="primary" @click="save(item,imgs[item-1])">保存</el-button>
        </div>
      </div>
    </div>

    <div class="title margin-top">首页下方广告</div>
    <div class="flex wrap">
      <div class="list flex align-center">
        <img-uploader class="smaller" @selected="a_img=$event" :img="a_img"></img-uploader>
        <div class="btn-container">
          <el-button type="primary" @click="a_save">保存</el-button>
        </div>
      </div>
    </div>

    <div class="title margin-top">回收页面广告图</div>
    <div class="flex wrap">
      <div class="list flex align-center">
        <img-uploader class="small" @selected="recovery_img=$event" :img="recovery_img"></img-uploader>
        <div class="btn-container">
          <el-button type="primary" @click="recovery_save">保存</el-button>
        </div>
      </div>
    </div>

    <div class="title margin-top">维修页面广告图</div>
    <div class="flex wrap">
      <div class="list flex align-center" v-for="item in 3" :key="item">
        <img-uploader class="big" ref="img_uploader_repair" @selected="repair_imgs[item-1]=$event" :img="repair_imgs[item-1]"></img-uploader>
        <div class="btn-container">
          <el-button type="danger" @click="repair_remove(item,repair_imgs[item-1])" v-if="item!=1">移除</el-button>
          <el-button type="primary" @click="repair_save(item,repair_imgs[item-1])">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgUploader from '../components/ImgUploader.vue';
import util from '../assets/js/util';

export default {
  components: {
    ImgUploader
  },
  data() {
    return {
      imgs: null,
      a_img: null,
      recovery_img: null,
      repair_imgs: null
    }
  },
  methods: {
    //删除
    remove: util.throttle(function(index) {
      util.confirm('提示','确定删除该图片？').then(() => {
        if(!/^http/.test(this.imgs[index-1])) {
          this.$refs.img_uploader[index-1].img_src='';
          return util.msg('移除成功');
        }
        const loading=util.loading();
        this.$axios.post('/index/activity/remove',{ index }).then(res => {
          loading.close();
          const { data }=res;
          if(data.code==1) {
            this.$refs.img_uploader[index-1].img_src='';
            util.msg(data.msg);
          }
        })
      }).catch(() => { })
    }),
    //维修删除
    repair_remove: util.throttle(function(index) {
      util.confirm('提示','确定删除该图片？').then(() => {
        if(!/^http/.test(this.repair_imgs[index-1])) {
          this.$refs.img_uploader_repair[index-1].img_src='';
          return util.msg('移除成功');
        }
        const loading=util.loading();
        this.$axios.post('/index/activity/repair_remove',{ index }).then(res => {
          loading.close();
          const { data }=res;
          if(data.code==1) {
            this.$refs.img_uploader_repair[index-1].img_src='';
            util.msg(data.msg);
          }
        })
      }).catch(() => { })
    }),

    //保存
    save: util.throttle(function(index,img) {
      if(/^http/.test(this.imgs[index-1])) return util.msg('保存成功');
      const loading=util.loading();
      this.$axios.post('index/activity/save',{ index,img }).then(res => {
        loading.close();
        const { data }=res;
        if(data.code==1) {
          this.imgs[index-1]=data.img;
          util.msg(data.msg);
        }
      })
    }),

    //广告保存
    a_save() {
      if(/^http/.test(this.a_img)) return util.msg('保存成功');
      const loading=util.loading();
      this.$axios.post('index/activity/asave',{ a_img: this.a_img }).then(res => {
        loading.close();
        const { data }=res;
        if(data.code==1) {
          this.a_img=data.a_img;
          util.msg(data.msg);
        }
      })
    },

    //回收广告图保存
    recovery_save() {
      if(/^http/.test(this.recovery_img)) return util.msg('保存成功');
      const loading=util.loading();
      this.$axios.post('index/activity/recovery_save',{ recovery_img: this.recovery_img }).then(res => {
        loading.close();
        const { data }=res;
        if(data.code==1) {
          this.recovery_img=data.recovery_img;
          util.msg(data.msg);
        }
      })
    },

    //维修广告图保存
    repair_save: util.throttle(function(index,img) {
      if(/^http/.test(this.repair_imgs[index-1])) return util.msg('保存成功');
      const loading=util.loading();
      this.$axios.post('index/activity/repair_save',{ index,img }).then(res => {
        loading.close();
        const { data }=res;
        if(data.code==1) {
          this.repair_imgs[index-1]=data.img;
          util.msg(data.msg);
        }
      })
    }),
  },

  //渲染
  created() {
    const loading=util.loading();
    this.$axios.post('/index/activity/render').then(res => {
      loading.close();
      this.imgs=res.data.imgs;
      this.a_img=res.data.a_img;
      this.recovery_img=res.data.recovery_img,
        this.repair_imgs=res.data.repair_imgs
    })
  }
}
</script>

<style scoped>
.upload {
  width: 355px;
  background: #f5f5f5;
}
.big.upload {
  height: 156.2px;
}
.small.upload {
  height: 142px;
}
.smaller.upload {
  height: 124.25px;
}

::v-deep .el-button + .el-button {
  margin-left: 0;
  margin-top: 10px;
}

.container {
  padding: 0 10px 50px 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 15px 10px;
  border-radius: 5px;
  background: #f5f5f5;
}

.title.margin-top {
  margin-top: 80px;
}

.flex.wrap {
  flex-wrap: wrap;
}

.list {
  margin: 20px;
}

.btn-container button {
  display: block;
}
</style>