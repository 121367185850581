/*
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-16 16:43:32
 */

import Vue from 'vue';

const that = Vue.prototype;

//精确计算 ---- + - * / ----
export function exact(num1, num2, type = 'add') {
    num1 = parseFloat(num1);
    num2 = parseFloat(num2);
    if (type == 'add') {
        return Math.round(num1 * 100 + num2 * 100) / 100;
    } else if (type == "subtract") {
        return Math.round(num1 * 100 - num2 * 100) / 100;
    } else if (type == "multiply") {
        return Math.round((num1 * 10) * (num2 * 10)) / 100;
    } else if (type == 'devide') {
        return Math.round((num1 * 100) / num2) / 100;
    }
}

export function loading() {
    const loading = that.$loading({
        lock: true,
        spinner: "el-icon-loading",
        background: "rgba(255,255,255,.05)",
    });
    return loading;
}

export function alert(title, msg, callback = () => {}, confirmText = '确定') {
    const alert = that.$alert(msg, title, {
        confirmButtonText: confirmText,
        callback
    });
    return alert;
}

export function confirm(title, msg, type = 'warning', o = {}) {
    const confirm = that.$confirm(msg, title, {
        type,
        ...o
    })
    return confirm;
}

export function prompt(title, msg, o = {}) {
    const prompt = that.$prompt(msg, title, {
        ...o
    });
    return prompt;
}

export function msg(message, type = 'success') {
    const msg = that.$message({
        type,
        message
    });
    return msg;
}

export function throttle(fn, wait = 1000) {
    let pre = 0;
    return function() {
        let now = Date.now();
        if (now - pre > wait) {
            fn.apply(this, arguments);
            pre = now;
        }
    }
}

export function createSocket(o) {
    let ws = new WebSocket(o.url),
        ping = null;

    ws.onopen = function(evt) {
        o.onopen(ws, evt);
        ping = setInterval(() => {
            ws.send(JSON.stringify({ type: 'ping' }));
        }, 30000);
    };

    ws.onmessage = function(evt) {
        o.onmessage(evt);
    };

    ws.onclose = function(evt) {
        msg('连接已断开', 'error');
        if (ping) clearInterval(ping);
        o.onclose(evt);
    };
    ws.onerror = function() {
        msg('连接状态异常，请检查在线状态', 'error');
        o.onerror();
    }

    return ws;
}

export const ossurl = 'https://zntu-phone.oss-cn-hangzhou.aliyuncs.com/';

export default {
    ossurl,
    exact,
    loading,
    alert,
    confirm,
    prompt,
    msg,
    throttle
}