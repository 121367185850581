/*
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-17 00:26:23
 */
import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router);

import LoginBackground from '../../pages/LoginBackground.vue';
import Person from '../../pages/Person.vue';
import Warehouse from '../../pages/Warehouse.vue';
import Product from '../../pages/Product.vue';
import ProductClass from '../../pages/ProductClass.vue';
import ProductOld from '../../pages/ProductOld.vue';
import Repass from '../../pages/Repass.vue';
import Recovery from '../../pages/Recovery.vue';
import ProductOrder from '../../pages/ProductOrder.vue';
import Repair from '../../pages/Repair.vue';
import Activity from '../../pages/Activity.vue';
import DefaultPerson from '../../pages/DefaultPerson.vue';
import RecoveryPrice from '../../pages/RecoveryPrice.vue';
import Customer from '../../pages/Customer.vue';
import RecoveryImg from '../../pages/RecoveryImg.vue';

const routes = [
    { path: '/', redirect: '/loginBackground' },
    { path: '/loginBackground', name: 'loginBackground', component: LoginBackground },
    { path: '/activity', name: 'activity', component: Activity },
    { path: '/person', name: 'person', component: Person },
    { path: '/warehouse', name: 'warehouse', component: Warehouse },
    { path: '/product', name: 'product', component: Product },
    { path: '/productClass', name: 'productClass', component: ProductClass },
    { path: '/productOld', name: 'productOld', component: ProductOld },
    { path: '/repass', name: 'repass', component: Repass },
    { path: '/productorder', name: 'productorder', component: ProductOrder },
    { path: '/recovery', name: 'recovery', component: Recovery },
    { path: '/repair', name: 'repair', component: Repair },
    { path: '/defaultperson', name: 'defaultperson', component: DefaultPerson },
    { path: '/recoveryprice', name: 'recoveryprice', component: RecoveryPrice },
    { path: '/customer', name: 'customer', component: Customer },
    { path: '/recoveryimg', name: 'recoveryimg', component: RecoveryImg }
]

const router = new Router({ routes })

export default router;