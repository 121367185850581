<template>
  <div class="container" v-if="tabs!==null">
    <div class="top-box">
      <el-tabs class="top-tab" v-model="activeId" @edit="topTabEdit" :before-leave="switch_class" editable>
        <el-tab-pane v-for="item in tabs" :key="item.uniqid" :label="item.name" :name="item.uniqid"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="product-container">
      <div class="items flex justify-center alilgn-center" v-for="item in products_show" :key="item.uniqid">
        <img class="img" :src="item.img">
      </div>
      <div class="no-product" v-if="products_show.length==0">暂无商品...</div>
      <el-button class="product-btn" type="primary" size="small" @click="choose_product">选择商品</el-button>
    </div>

    <!-- dialog -->
    <el-dialog class="img-dialog" title="选择商品至分类" :visible.sync="dialog" width="60%" :close-on-click-modal="false">
      <div class="dialog-product-container">
        <div class="list" v-for="(item,index) in products" :key="item.uniqid" @click="checkIt(index)">
          <div class="img-box flex jsutify-center align-center">
            <img class="img" :src="item.img" />
            <i class="icon el-icon-success" v-if="item.checked"></i>
          </div>
          <div class="name">{{item.name}}</div>
        </div>
        <el-button type="success" size="small" class="dialog-btn" @click="sub">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import imgUploader from '../components/ImgUploader.vue';
import util from '../assets/js/util';

export default {
  components: {
    // imgUploader
  },
  data() {
    return {
      activeId: '',
      tabs: null,
      search_inp: '',
      products_show: null,
      products: null,
      dialog: false
    };
  },
  methods: {
    //选项卡操作
    topTabEdit(currentId,action) {
      if(action=='add') {
        util.prompt('创建商品分类','请输入分类名称',{
          closeOnClickModal: false,
        }).then(({ value }) => {
          if(!value) util.msg('创建失败，未输入分类名称','error');
          if(this.tabs.includes(value)) return util.msg('该分类已存在','error');
          this.$axios.post('/index/classes/add_class',{ name: value }).then(res => {
            const { data }=res;
            if(data.code==1) {
              this.tabs.push({ name: value,uniqid: data.id });
              this.activeId=data.id;
              util.msg('添加成功');
            }
          })
        }).catch(() => { });
      }

      if(action=='remove') {
        if(this.tabs.length<=1) return util.msg('至少保留一个分类','error');
        util.confirm('提示','删除该商品分类后，该分类下的所有商品的分类状态将被清除，确认继续？').then(() => {
          this.$axios.post('/index/classes/remove',{ uniqid: currentId }).then(res => {
            const { data }=res;
            if(data.code==1) {
              let newTabs=[];
              this.tabs.forEach((e,i) => {
                if(e.uniqid!=currentId) newTabs.push(e);
                else if(this.activeId==currentId) { //如果删除的是选中的选项卡则进行切换
                  if(this.tabs[i+1]) this.activeId=this.tabs[i+1].uniqid;
                  else if(this.tabs[i-1]) this.activeId=this.tabs[i-1].uniqid;
                }
              })
              this.tabs=newTabs;
              util.msg('删除成功');
            }
          })
        }).catch(() => { })
      }
    },

    //选择商品
    choose_product() {
      const load=util.loading();
      this.$axios.post('/index/classes/product_render',{ class_id: this.activeId }).then(res => {
        load.close();
        this.products=res.data.products;
        this.dialog=true;
      })
    },

    //选中商品
    checkIt: util.throttle(function(index) {
      this.products[index].checked=!this.products[index].checked;
    },300),

    //修改完成
    sub() {
      const load=util.loading();
      this.$axios.post('/index/classes/check',{ products: this.products,class_id: this.activeId }).then(res => {
        load.close();
        this.dialog=false;
        this.products_show=res.data.products_checked;
        util.msg('操作成功');
      })
    },

    class_load() {
      this.$axios.post('/index/classes/render',{ id: this.activeId }).then(res => {
        const data=res.data;
        if(data.classes) {
          this.tabs=data.classes;
          this.activeId=data.classes[0].uniqid
        }
        this.products_show=data.products_checked;
      })
    },

    switch_class(c,o) {
      if(c==o) return;
      this.activeId=c;
      this.class_load();
    },
  },

  created() {
    this.class_load();
  }
}
</script>

<style scoped>
::v-deep .el-tabs__active-bar {
  background-color: #409eff;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff;
}
::v-deep .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
::v-deep .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
::v-deep .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
::v-deep .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-form-item.inline,
::v-deep .el-checkbox__input,
::v-deep .el-checkbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
::v-deep .types-box .el-form-item__content {
  flex-wrap: wrap;
  align-items: center;
  margin: -15px 0 0 -20px !important;
}
::v-deep .el-checkbox {
  align-items: center;
}
::v-deep .el-form-item__label {
  min-width: 70px;
}
::v-deep .el-button--primary {
  border-color: #412ba4;
  background-color: #412ba4;
}
.top-box {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.product-container {
  position: relative;
  width: 100%;
  min-height: 500px;
  padding: 0 10px 10px 0;
  border-radius: 5px;
  box-sizing: border-box;
  background: #f5f5f5;
}

.product-container .items {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin: 10px 0 0 10px;
  background: #fff;
}

.product-container .items .img {
  width: 100%;
  height: 100%;
}

.product-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.btn-container {
  margin-top: 20px;
}

::v-deep .btn-container button {
  color: #fff;
  background: #412ba4;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.el-dialog__body {
  position: relative;
}

.dialog-product-container {
  width: 100%;
  height: 70vh;
  padding: 0 15px 60px 0;
  overflow-y: visible;
  box-sizing: border-box;
}

.dialog-btn {
  position: absolute;
  right: 15px;
  bottom: 12px;
}

.dialog-product-container .list {
  display: inline-block;
  width: 80px;
  margin: 15px 0 0 15px;
  cursor: pointer;
  overflow: unset;
}

.dialog-product-container .img-box {
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 5px;
  overflow: unset;
  background: #f5f5f5;
}

.dialog-product-container .img-box .img {
  width: 100%;
  max-height: 100%;
}

.dialog-product-container .img-box .icon {
  position: absolute;
  top: -7px;
  right: -7px;
  font-size: 18px;
  color: #39ae67;
}

.dialog-product-container .name {
  width: 100%;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-product {
  font-size: 14px;
  color: #9e9e9e;
  padding: 10px;
}
</style>