<template>
  <div class="container">
    <div class="top-box flex align-center">
      <div class="title">商品订单<span class="tip">(鼠标移至“收货人”栏可查看完整收货人信息)</span></div>
      <div class="btn-group flex">
        <span class="list border-right" :class="type_index==1?'active':''" @click="type_switch(1)">待发货</span>
        <span class="list" :class="type_index==2?'active':''" @click="type_switch(2)">已发货</span>
      </div>
      <div class="refresh" @click="type_switch(type_index,true)"><i class="el-icon-refresh"></i></div>
    </div>
    <el-table v-if="tableData!=null" stripe :data="tableData.filter(data => !search || data.user_address.name.includes(search)|| data.uniqid.includes(search)||data.agent_info.name.includes(search)||data.product_info.name.includes(search))" style="width: 100%">
      <el-table-column label="订单编号" prop="uniqid"></el-table-column>
      <el-table-column label="收货人">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p><span class="item-name">姓名: </span>{{ scope.row.user_address.name }}</p>
            <p><span class="item-name">手机号: </span>{{ scope.row.user_address.phone }}</p>
            <p><span class="item-name">收货地址: </span>{{ scope.row.user_address.address }}</p>
            <div slot="reference">
              {{ scope.row.user_address.name }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="销售代理">
        <template slot-scope="scope">
          <span v-if="scope.row.agent_info===null">无</span>
          <el-popover trigger="hover" placement="top" v-else>
            <p><span class="item-name">姓名: </span>{{ scope.row.agent_info.name }}</p>
            <p><span class="item-name">销售人员id: </span>{{ scope.row.agent_info.id }}</p>
            <div slot="reference">
              {{ scope.row.agent_info.name }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" prop="product_info.name"></el-table-column>
      <el-table-column label="下单时间" prop="add_time"></el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="{}">
          <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
        </template>
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="detailInfo(scope.row)">详细信息</el-button>
          <el-button size="mini" type="success" @click="confirmPost(scope.row.uniqid)" v-if="type_index==1">确认发货</el-button>
          <el-button size="mini" type="danger" @click="agent_settle(scope.row.uniqid,scope.$index)" v-if="type_index==2&&scope.row.agent_info&&scope.row.agent_info.id!='-'&&scope.row.agent_settle==1">提成结算</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 订单详情 -->
    <el-dialog class="detail-dialog" title="订单详情" :visible.sync="detail.dialog">
      <div class="list flex align-center"><strong>订单状态</strong>{{detail.status==1?'待':'已'}}发货</div>
      <div class="list flex align-center"><strong>订单金额</strong>¥{{detail.product_info.price}}</div>
      <div class="list flex align-center"><strong>商品信息</strong>{{detail.product_info.name}} {{detail.product_info.color}} {{detail.product_info.size}}</div>
      <div class="list flex align-center"><strong>订单号码</strong>{{detail.uniqid}}</div>
      <div class="list flex align-center"><strong>交易单号</strong>{{detail.wxpay_id}}</div>
      <div class="list flex align-center"><strong>收货人</strong>{{detail.user_address.name}} {{detail.user_address.phone}}</div>
      <div class="list flex align-center"><strong>收货地址</strong>{{detail.user_address.address}}</div>
      <div class="list flex align-center"><strong>提成结算</strong>{{detail.agent_settle=='1'?'未':'已'}}结算</div>
      <div class="list flex align-center"><strong>销售代理</strong>{{detail.agent_info===null?'无':`${detail.agent_info.name} ${detail.agent_info.id}`}}</div>
      <div class="list flex align-center"><strong>下单时间</strong>{{detail.add_time}}</div>
      <div v-if="detail.post_info" style="margin-top:15px">
        <div class="list flex align-center"><strong>发货人</strong>{{detail.post_info.name}}</div>
        <div class="list flex align-center"><strong>发货信息</strong>{{detail.post_info.company}} {{detail.post_info.number}}</div>
      </div>
    </el-dialog>

    <!-- 确认发货 -->
    <el-dialog title="邮寄信息" :visible.sync="kuaidi_dialog" :close-on-click-modal="false" @closed="kuaidi_dialog_close">
      <el-form :model="post_form">
        <el-form-item label="快递公司" label-width="120px">
          <el-input type="text" v-model="post_form.company" placeholder="请输入快递品牌例如：中通快递" autocomplete="off" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="快递编号" label-width="120px">
          <el-input type="text" v-model="post_form.number" placeholder="请输入您已寄出的快递编号" autocomplete="off" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="邮寄人姓名" label-width="120px">
          <el-input type="text" v-model="post_form.name" placeholder="请输入快递发货人的姓名" autocomplete="off" maxlength="10"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="kuaidi_dialog=false">取 消</el-button>
        <el-button size="medium" type="primary" @click="send">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { msg,loading,confirm } from '../assets/js/util';

export default {
  data() {
    return {
      type_index: 0,
      tableData: null,
      search: '',
      form: {},
      detail: {
        dialog: false,
        user_address: {},
        agent_info: {},
        product_info: {}
      },
      kuaidi_dialog: false,
      post_form: {}
    }
  },
  methods: {
    //订单类型切换
    type_switch(index,refresh=false) {
      if(index==this.type_index&&!refresh) return;
      this.type_index=index;
      this.tableData=null;
      const load=loading();
      this.$axios.post('index/product_order/render',{ type: index }).then(res => {
        load.close();
        this.tableData=res.data.orders;
        this.$parent.badges[0]=0;
      })
    },

    //订单详情
    detailInfo(data) {
      this.detail={
        dialog: true,
        ...data
      }
    },

    //确认发货
    confirmPost(id) { //弹出窗口
      this.post_form.uniqid=id;
      this.kuaidi_dialog=true;
    },
    kuaidi_dialog_close() { //关闭窗口 - 清空表单
      this.post_form={}
    },
    send() { //确认发货
      const { post_form }=this;
      if(!post_form.company) return msg('请输入快递公司');
      if(!post_form.number) return msg('请输入您已寄出的快递编号');
      if(!post_form.name) return msg('请输入快递发货人的姓名');

      confirm('提示','发货信息不可修改，请保证输入无误！确认继续？').then(() => {
        const load=loading();
        this.$axios.post('/index/product_order/update_order',post_form).then(res => {
          load.close();
          const { data }=res;
          if(data.code==1) {
            this.post_form={};
            this.kuaidi_dialog=false;
            this.tableData=this.tableData.filter(e => e.uniqid!=post_form.uniqid);
            msg('已确认发货');
          }
        })
      }).catch(() => { })
    },

    // 提成结算
    agent_settle(id,index) {
      confirm('提示','确定已将该笔订单的提成结算给销售代理？').then(() => {
        const load=loading();
        this.$axios.post('/index/product_order/agent_settle',{ id }).then(res => {
          load.close();
          if(res.data.code==1) {
            msg('结算成功');
            this.tableData[index].agent_settle=2;
          }
        })
      }).catch(() => { })
    }
  },

  created() {
    this.type_switch(1);
  }
}
</script>

<style scoped>
.container {
  padding: 0 10px 100px 10px;
}

::v-deep .el-input__inner {
  max-width: 260px;
  padding: 17px 12px;
}
::v-deep .el-textarea__inner {
  max-width: 360px;
  padding: 10px 12px;
}
::v-deep .el-dialog {
  max-width: 600px;
  width: auto;
}

::v-deep .el-dialog__body {
  padding-bottom: 10px 20px;
}

.top-box .title {
  font-size: 16px;
  color: #393d49;
  font-weight: bold;
  letter-spacing: 1px;
}

.top-box .title .tip {
  font-size: 12px;
  color: #9e9e9e;
  font-weight: normal;
  letter-spacing: 0.3px;
}

.top-box .btn-group {
  border: 1px solid #393d49;
  border-radius: 5px;
  margin-left: 30px;
}

.top-box .btn-group .list {
  display: inline-block;
  font-size: 12px;
  color: #393d49;
  padding: 5px 10px;
  transition: all 0.2s;
  cursor: pointer;
}

.top-box .btn-group .list.border-right {
  border-right: 1px solid #393d49;
}

.top-box .btn-group .list.active {
  color: #fff;
  background: #393d49;
}

.top-box .refresh {
  color: #fff;
  padding: 3px 6px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 30px;
  border-radius: 3px;
  background: #393d49;
}

::v-deep .detail-dialog .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .detail-dialog .list {
  font-size: 13px;
  letter-spacing: 0.8px;
  padding-bottom: 15px;
}

.detail-dialog strong {
  display: inline-block;
  width: 80px;
}
</style>