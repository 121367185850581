<template>
  <div class="page-container">
    <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom" v-for="(item,index) in list" :key="item.img">
      <img-uploader :zoomWidth="2000" :img="'https://zntu-phone.oss-cn-hangzhou.aliyuncs.com/recovery/imgs/'+list[index].img+'.jpg?x-oss-process=style/suolue'" @selected="up(index,$event)"></img-uploader>
    </el-tooltip>
  </div>
</template>

<script>
import imgUploader from '../components/ImgUploader.vue';
import { msg,loading } from '../assets/js/util';

export default {
  components: {
    imgUploader
  },
  data() {
    return {
      list: [{
        name: '苹果有保',
        img: 'pingguoyoubao'
      },{
        name: '苹果过保',
        img: 'pingguoguobao'
      },{
        name: '华为',
        img: 'huawei'
      },{
        name: '荣耀',
        img: 'rongyao'
      },{
        name: 'OPPO',
        img: 'oppo'
      },{
        name: 'vivo',
        img: 'vivo'
      },{
        name: 'iQOO',
        img: 'iqoo'
      },{
        name: 'realme',
        img: 'realme'
      },{
        name: '小米',
        img: 'xiaomi'
      },{
        name: '红米',
        img: 'hongmi'
      },{
        name: '黑鲨',
        img: 'heisha'
      },{
        name: '三星',
        img: 'sanxing'
      },{
        name: '一加',
        img: 'yijia'
      },{
        name: '魅族',
        img: 'meizu'
      },{
        name: '努比亚',
        img: 'nubiya'
      },{
        name: '中兴',
        img: 'zhongxing'
      },{
        name: '8848',
        img: '8848'
      },{
        name: '华为手表',
        img: 'huaweishoubiao'
      },{
        name: '苹果 Watch',
        img: 'pingguoshoubiao'
      },{
        name: '苹果AirPods',
        img: 'pingguoerji'
      },{
        name: '苹果电脑',
        img: 'pingguodiannao'
      },{
        name: 'oppo平板',
        img: 'oppopingban'
      },{
        name: '荣耀平板',
        img: 'rongyaopingban'
      },{
        name: '华为平板',
        img: 'huaweipingban'
      },{
        name: '小米平板',
        img: 'xiaomipingban'
      },{
        name: '苹果iPad',
        img: 'pingguopingban'
      }]
    };
  },

  methods: {
    up(index,img) {
      const load=loading();
      this.$axios.post('index/recovery/imgupload',{ img,name: this.list[index].img }).then(res => {
        load.close();
        if(res.data.code==1) msg('上传成功');
      })
    }
  }
}
</script>

<style scoped>
::v-deep .upload {
  width: 250px;
  height: 540px;
  margin: 15px 0 0 20px;
  background: #f5f5f5;
}
</style>