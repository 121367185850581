<!--
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-15 17:05:06
-->
<template>
  <div class="container">
    <!-- 水平导航栏 -->
    <el-menu class="row-nav" ref="menu" :default-active="activeIndex" mode="horizontal" @select="handleSelect" text-color="#ffffffa2" background-color="#23262E" active-text-color="#ffffff">
      <div class="motto-container" :style="'height:' + (rmenu_height - 92) + 'px'">
        <img src="../assets/motto.png" />
      </div>
      <el-submenu index="personal">
        <template slot="title">个人中心</template>
        <router-link to="/repass">
          <el-menu-item class="bk" index="repass">修改密码</el-menu-item>
        </router-link>
        <el-menu-item class="bk" index="logout" @click="logout()">退出登录</el-menu-item>
      </el-submenu>
      <div class="online-container flex justify-center align-center">
        <online-status ref="online" @breakSocket="breakSocket" @refreshSocket="refreshSocket"></online-status>
        <span class="voice-container" @click="changeVoiceStatus">
          <i :class="'iconfont icon-'+(voiceStatus==1?'yinliang':'jingyin')"></i>
        </span>
      </div>
    </el-menu>

    <div class="flex justify-between">
      <!-- 竖直导航栏 -->
      <el-row class="tac">
        <el-menu router :default-active="activeIndex" class="el-menu-vertical vertical" @select="handleSelect" text-color="#ffffffa2" background-color="#23262e" active-text-color="#ffffff" :style="'height:calc(100vh - ' + rmenu_height + 'px)'">
          <el-menu-item index="loginBackground">登录页面背景</el-menu-item>
          <el-menu-item index="activity">广告图设置</el-menu-item>
          <el-menu-item index="product">新机</el-menu-item>
          <el-menu-item index="productOld">二手机</el-menu-item>
          <el-menu-item index="productClass">商品分类</el-menu-item>
          <el-menu-item index="recoveryprice">估价设置</el-menu-item>
          <el-menu-item index="recoveryimg">回收图片</el-menu-item>
          <el-menu-item index="defaultperson">默认联系人</el-menu-item>
          <el-menu-item index="person">人员管理</el-menu-item>
          <el-menu-item index="warehouse">分仓管理</el-menu-item>
          <el-badge :value="badges[3]" :hidden="badges[3]==0">
            <el-menu-item index="customer">合作咨询</el-menu-item>
          </el-badge>
          <el-badge :value="badges[0]" :hidden="badges[0]==0">
            <el-menu-item index="productorder">商品订单</el-menu-item>
          </el-badge>
          <el-badge :value="badges[1]" :hidden="badges[1]==0">
            <el-menu-item index="recovery">回收订单</el-menu-item>
          </el-badge>
          <el-badge :value="badges[2]" :hidden="badges[2]==0">
            <el-menu-item index="repair">维修订单</el-menu-item>
          </el-badge>
        </el-menu>
      </el-row>

      <!-- 右侧内容区域 -->
      <div class="right-area" :style="'height:calc(100vh - ' + rmenu_height + 'px)'">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { confirm,msg,createSocket } from "../assets/js/util";
import OnlineStatus from "../components/OnlineStatus.vue";

const default_route='loginBackground';
var ws=null;
export default {
  components: {
    OnlineStatus
  },
  data() {
    return {
      activeIndex: default_route,
      rmenu_height: 0,
      badges: { '0': 0,'1': 0,'2': 0,'3': 0 },
      voiceStatus: 0
    };
  },
  methods: {
    //导航选中ui
    handleSelect(key) {
      this.activeIndex=key;
      switch(key) {
        case 'productorder':
          this.badges[0]=0;
          break;
        case 'recovery':
          this.badges[1]=0;
          break;
        case 'repair':
          this.badges[2]=0;
          break;
        case 'customer':
          this.badges[3]=0;
          break;
      }
    },

    //退出登录
    logout() {
      confirm("提示","确定退出登录？","warning")
        .then(() => {
          if(this.$route.name!=default_route) {
            this.$router.replace({ path: '/' });
          }
          localStorage.removeItem("Authorization");
          window.history.go(0);
        })
        .catch(() => { });
    },

    //建立socket连接
    socketConnect() {
      const _this=this;
      ws=createSocket({
        url: 'wss://api.phone.lgqhealer.cn:2348',
        onopen(ws) {
          ws.send(JSON.stringify({ token: localStorage.getItem('Authorization') }));
          _this.$notify({
            title: "连接成功",
            message: "现在您可以实时收取通知",
            type: "success"
          });
          if(_this.voiceStatus==1) _this.$sound.play();
          _this.$refs.online.onlineStatus=1;
        },
        onmessage(evt) {
          const data=JSON.parse(evt.data);
          if(data.tip!==1) return;
          _this.$notify({
            title: data.title,
            message: data.msg
          })
          if(_this.voiceStatus==1) _this.$sound.play();
          _this.badges[data.type]++;
        },
        onclose() {
          _this.$refs.online.onlineStatus=0;
        }
      })
      return ws;
    },

    //断开socket连接
    breakSocket() {
      confirm("提示","确定要更改在线状态为“离线”？","warning")
        .then(() => {
          if(!ws||ws.readyState!==1) return msg('当前已处于离线状态','error');
          ws.close();
          ws=null;
        })
        .catch(() => { });
    },

    //刷新socket连接
    refreshSocket() {
      confirm("提示","重连会导致连接的短暂断开，确定要重新发起连接？","warning")
        .then(() => {
          if(ws) ws.close();
          ws=this.socketConnect();
        })
        .catch(() => { });
    },

    //打开/关闭提示音
    changeVoiceStatus() {
      this.voiceStatus=this.voiceStatus==0? 1:0;
    }
  },
  created() {
    this.activeIndex=this.$route.name;
    if(localStorage.getItem('voice')==1) {
      this.voiceStatus=1;
      localStorage.removeItem('voice');
    }
  },
  mounted() {
    this.socketConnect();
    this.rmenu_height=this.$refs.menu.$el.offsetHeight+60;
  },
};
</script>

<style scoped>
.el-menu-vertical {
  padding-bottom: 100px;
}
.el-menu-item {
  letter-spacing: 1px;
}
.el-menu-item:hover {
  color: #fff !important;
}
.el-menu--horizontal > .el-submenu,
.el-menu--horizontal > .el-menu-item {
  float: right;
}
.vertical .el-menu-item.is-active,
.el-menu-item.is-active.bk {
  background: #009688 !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: 0;
}
.el-row {
  width: 160px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid;
  border-bottom-color: #009688 !important;
}
.row-nav {
  position: relative;
}
.motto-container {
  position: absolute;
  display: inline-block;
  padding: 16px;
}
.motto-container img {
  height: 100%;
}
.right-area {
  width: calc(100vw - 160px);
}
.el-menu-vertical,
.right-area {
  overflow-y: auto;
}
.right-area {
  padding: 12px 10px;
  padding-bottom: 60px;
}
.online-container {
  width: 100px;
  height: 60px;
  float: right;
  user-select: none;
}
.voice-container {
  color: #9e9e9e;
  font-weight: bold;
  padding: 3px;
  margin-left: 7px;
  margin-top: 2px;
  cursor: pointer;
}
a {
  text-decoration: none;
}
.el-badge {
  width: 100%;
}
::v-deep .el-badge__content.is-fixed {
  top: 20px;
  right: 30px;
}
::v-deep .el-badge__content {
  border: none;
}
</style>