/*
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-19 11:27:05
 */
import Axios from 'axios';
import { alert, msg } from './util.js';

Axios.defaults.baseURL = '/api';

Axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("Authorization");
        if (token) {
            config.headers.common.Authorization = token;
        }
        return config;
    },
    error => {
        return Promise.error(error);
    }
);


function invalid_token(response, timeout = false) {
    if (!response.request.responseURL.includes('is_login')) {
        alert('提示', (timeout ? '长时间未操作页面，' : '') + '登录状态已过期，请重新登录！', () => {
            window.history.go(0);
        });
    }
}
Axios.interceptors.response.use(
    response => {
        if (response) {
            const data = response.data;
            switch (data.code) {
                case 4001:
                    invalid_token(response, true);
                    break;
                case 4002:
                    invalid_token(response);
                    break;
                case 1:
                    if (data._token) {
                        localStorage.setItem('Authorization', data._token);
                    }
                    break;
                default:
                    msg(data.msg, 'error');
                    break;

            }
        }
        return response;
    }
);

export default Axios;