<template>
  <div class="container" v-if="products!==null">
    <div class="top-box">
      <el-tabs class="top-tab" v-model="activeId" @edit="topTabEdit" :before-leave="switch_class" editable>
        <el-tab-pane v-for="item in tabs" :key="item.uniqid" :label="item.name" :name="item.uniqid"></el-tab-pane>
      </el-tabs>
      <el-input class="search" v-model="search_inp" placeholder="按回车搜索" @keyup.enter.native="search()"></el-input>
    </div>

    <div class="form-container" label-width="80px" v-for="(item, p_index) in products" :key="item.uniqid ? item.uniqid : item.key">
      <el-form>
        <el-form-item label="物品图片">
          <img-uploader class="upload" :img="item.img" @selected="img_choosed($event,p_index)"></img-uploader>
        </el-form-item>

        <div class="flex">
          <el-form-item label="物品名称">
            <el-input class="middle inline name" placeholder="请输入物品名称" v-model="item.name"></el-input>
          </el-form-item>
          <el-form-item class="inline" label="最高价">
            <el-input type="number" placeholder="¥" v-model="item.price_upest"></el-input>
          </el-form-item>
          <el-form-item class="inline" label="最低价">
            <el-input type="number" placeholder="¥" v-model="item.price_lowest"></el-input>
          </el-form-item>
        </div>

        <div class="own-add-container">
          <div class="title-box flex align-center">
            <div class="unset-overflow">
              <el-form-item class="title" label="物品信息 (以下为物品信息选项，请至少添加一项供用户选择)"></el-form-item>
              <div class="border-img"></div>
            </div>
            <el-button class="btn is-round" size="small" icon="el-icon-plus" @click="out_add(p_index,'info')"></el-button>
          </div>
          <div class="types-box flex align-center">
            <el-form-item class="inline flex align-center" :label="info.name" v-for="(info,i_index) in item.infos" :key="i_index">
              <div class="specification-box flex" v-for="(option,o_index) in info.item" :key="o_index">
                <div class="bigger flex align-center">
                  <span class="txt">选项</span>
                  <el-input class="need_focus" type="text" placeholder="请输入选项内容" maxlength="20" v-model="option.content"></el-input>
                </div>
                <div class="flex align-center margin-left">
                  <span class="txt">减价</span>
                  <el-input type="number" placeholder="¥" v-model="option.price_subtract"></el-input>
                </div>
                <div class="flex align-center margin-left">
                  <span class="txt">降级</span>
                  <el-input type="number" placeholder="分数" v-model="option.score_subtract"></el-input>
                </div>
                <i class="icon el-icon-circle-close" @click="in_delete(p_index,i_index,o_index,'info')"></i>
              </div>
              <el-button class="btn" size="small" type="primary" @click="in_add(p_index,i_index,'info')">添加</el-button>
              <el-button class="btn" size="small" type="danger" @click="out_delete(p_index,i_index,'info')">删除</el-button>
            </el-form-item>
          </div>

          <div class="title-box margin-top flex align-center">
            <div class="unset-overflow">
              <el-form-item class="title" label="功能情况 (以下为功能情况选项，请至少添加一项供用户选择)"></el-form-item>
              <div class="border-img"></div>
            </div>
            <el-button class="btn is-round" size="small" icon="el-icon-plus" @click="out_add(p_index,'fn')"></el-button>
          </div>
          <div class="types-box flex align-center">
            <el-form-item class="inline flex align-center" :label="fn.name" v-for="(fn,f_index) in item.fns" :key="f_index">
              <div class="specification-box flex" v-for="(option,o_index) in fn.item" :key="o_index">
                <div class="bigger flex align-center">
                  <span class="txt">选项</span>
                  <el-input class="need_focus" type="text" placeholder="请输入选项描述" maxlength="20" v-model="option.content"></el-input>
                </div>
                <div class="flex align-center margin-left">
                  <span class="txt">减价</span>
                  <el-input type="number" placeholder="¥" v-model="option.price_subtract"></el-input>
                </div>
                <div class="flex align-center margin-left">
                  <span class="txt">降级</span>
                  <el-input type="number" placeholder="分数" v-model="option.score_subtract"></el-input>
                </div>
                <i class="icon el-icon-circle-close" @click="in_delete(p_index,f_index,o_index,'fn')"></i>
              </div>
              <el-button class="btn" size="small" type="primary" @click="in_add(p_index,f_index,'fn')">添加</el-button>
              <el-button class="btn" size="small" type="danger" @click="out_delete(p_index,f_index,'fn')">删除</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <div class=" right-button-container">
        <button class="small" style="background:#de594f;" @click="product_remove(p_index)">删除</button>
        <button class="small" style="background:#67c23a;margin-left:10px;" @click="product_save(p_index)">保存</button>
      </div>
    </div>
    <div class="page" v-if="page_count != null && !products.length == 0">
      <el-pagination background layout="prev, pager, next" :page-count="page_count" :current-page="cur_page" @current-change="page_change"></el-pagination>
    </div>
    <div class="btn-container add-product" @click="add_product" v-if="cur_page >= page_count">
      <button class="normal">添加新物品</button>
    </div>
  </div>
</template>

<script>
import imgUploader from '../components/ImgUploader.vue';
import util from '../assets/js/util';

export default {
  components: {
    imgUploader
  },
  data() {
    return {
      activeId: '',
      tabs: [],
      search_inp: '',
      products: null,
      page_count: null,
      cur_page: null
    };
  },
  methods: {
    //选项卡操作
    topTabEdit(currentId,action) {
      if(action=='add') {
        util.prompt('创建估价分类','请输入分类名称',{
          closeOnClickModal: false,
        }).then(({ value }) => {
          if(!value) util.msg('创建失败，未输入分类名称','error');
          if(this.tabs.includes(value)) return util.msg('该分类已存在','error');
          this.$axios.post('/index/rprice/add_classification',{ name: value }).then(res => {
            const { data }=res;
            if(data.code==1) {
              this.tabs.push({ name: value,uniqid: data.id });
              this.activeId=data.id;
              util.msg('添加成功');
            }
          })
        }).catch(() => { });
      }

      if(action=='remove') {
        if(this.tabs.length<=1) return util.msg('至少保留一个分类','error');
        util.confirm('提示','删除该估价分类后，该分类下的所有商品内容均将被删除，确认继续？').then(() => {
          this.$axios.post('/index/rprice/remove_classification',{ uniqid: currentId }).then(res => {
            const { data }=res;
            if(data.code==1) {
              let newTabs=[];
              this.tabs.forEach((e,i) => {
                if(e.uniqid!=currentId) newTabs.push(e);
                else if(this.activeId==currentId) { //如果删除的是选中的选项卡则进行切换
                  if(this.tabs[i+1]) this.activeId=this.tabs[i+1].uniqid;
                  else if(this.tabs[i-1]) this.activeId=this.tabs[i-1].uniqid;
                }
              })
              this.tabs=newTabs;
              util.msg('删除成功');
            }
          })
        }).catch(() => { })
      }
    },

    //添加物品
    add_product: util.throttle(function() {
      if(!this.products) this.products=[];
      this.products.push({
        img: '',
        name: '',
        price_upest: '',
        price_lowest: '',
        infos: [],
        fns: []
      });
    }),

    //选中图片
    img_choosed(src,p_index) {
      this.products[p_index].img=src;
    },

    //添加物品信息/功能情况
    out_add(p_index,type) {
      const prompt_desc=type=='info'? '物品信息':'功能情况';
      util.prompt(`添加${prompt_desc}项`,'请输入新项名称',{
        closeOnClickModal: false,
      }).then(({ value }) => {
        const infoOrFn=this.products[p_index][type=='info'? 'infos':'fns'];
        for(let item of infoOrFn) {
          if(item.name==value) return util.msg('该项已存在','error');
        }
        infoOrFn.push({
          name: value,
          item: []
        })
      }).catch(() => { })
    },
    //删除物品信息/功能情况
    out_delete(p_index,o_index,type) {
      util.confirm('提示','删除该项后，其下的选项也会被删除，确认继续？').then(() => {
        const infoOrFn=this.products[p_index][type=='info'? 'infos':'fns'];
        infoOrFn.splice(o_index,1);
      }).catch(() => { })
    },

    //添加选项
    in_add(p_index,o_index,type) {
      const infoOrFn=this.products[p_index][type=='info'? 'infos':'fns'];
      infoOrFn[o_index].item.push({
        content: '',
        price_subtract: '',
        score_subtract: ''
      })
    },
    //删除选项
    in_delete(pi,oi,ooi,type) {
      util.confirm('提示','确定删除该选项？').then(() => {
        const infoOrFnItem=this.products[pi][type=='info'? 'infos':'fns'][oi].item;
        infoOrFnItem.splice(ooi,1)
      }).catch(() => { })
    },

    //保存物品信息
    product_save: util.throttle(function(index) {
      const product=JSON.parse(JSON.stringify(this.products[index]));
      if(!product.img) return util.msg('请上传物品图片','error');
      if(!product.name) return util.msg('请输入物品名称','error');
      if(!product.price_upest) return util.msg('请输入最高价格','error');
      if(!product.price_lowest) return util.msg('请输入最低价格','error');
      if(product.infos.length<1) return util.msg('请添加至少一条物品信息项','error');
      if(product.fns.length<1) return util.msg('请添加至少一条功能情况项','error');

      const infoAndFn=[{ txt: '物品信息',obj: product.infos },{ txt: '功能情况',obj: product.fns }];
      for(const item of infoAndFn) {
        for(const iof of item.obj) {
          if(iof.item.length<1) return util.msg(item.txt+'项下至少包含一个选项','error')
          for(const option of iof.item) {
            if(!option.content||!option.price_subtract||!option.score_subtract) return util.msg(item.txt+'存在选项信息填写不完整','error')
          }
        }
      }

      const loading=util.loading();
      product.infos=JSON.stringify(product.infos);
      product.fns=JSON.stringify(product.fns);
      product.img=product.img.includes('http')? '':product.img;
      product.classification_id=this.activeId;

      this.$axios.post('/index/rprice/save',product).then(res => {
        loading.close();
        const { data }=res;
        if(data.code==1) {
          if(data.uniqid) this.products[index].uniqid=data.uniqid;
          util.msg(data.msg);
        }
      })
    }),

    //删除物品
    product_remove: util.throttle(function(index) {
      const _this=this;
      function deal() {
        _this.products=_this.products.filter((e,i) => i!=index);
        util.msg('删除成功');
      }

      util.confirm('提示','确定删除该物品？').then(() => {
        if(!this.products[index].uniqid) return deal();
        const uniqid=this.products[index].uniqid,
          loading=util.loading();
        this.$axios.post('/index/rprice/remove',{ uniqid }).then(res => {
          loading.close();
          const { data }=res;
          if(data.code==1) {
            deal();
          }
        })
      }).catch(() => { })
    }),

    //商品渲染
    classification_load(page,need_count=false) {
      const loading=util.loading();
      if(need_count) this.page_count=null;
      let send_data={
        classification_id: this.activeId,
        page,
        need_count,
        search: this.search_inp
      };
      this.$axios.post('/index/rprice/render',send_data).then(res => {
        loading.close();
        const { data }=res;
        this.cur_page=page;
        this.products=data.products;
        if(!send_data.classification_id) {
          this.tabs=data.classification;
          this.activeId=this.tabs[0].uniqid;
        }
        if(need_count) this.page_count=data.page_count;
      })
    },

    //切换分类
    switch_class(c,o) {
      this.search_inp='';
      if(c==o) return;
      this.activeId=c;
      this.classification_load(1);
    },
    //搜索商品
    search() {
      this.classification_load(1,true);
    },
    //切换分页
    page_change(e) {
      this.classification_load(e)
    }
  },

  created() {
    this.classification_load(1,true);
  }
}
</script>

<style scoped>
::v-deep .el-tabs__active-bar {
  background-color: #409eff;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff;
}
::v-deep .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
::v-deep .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
::v-deep .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}
::v-deep .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-form-item.inline,
::v-deep .el-checkbox__input,
::v-deep .el-checkbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
::v-deep .types-box .el-form-item__content {
  flex-wrap: wrap;
  align-items: center;
  margin: -15px 0 0 -20px !important;
}
::v-deep .el-checkbox {
  align-items: center;
}
::v-deep .el-form-item__label {
  min-width: 70px;
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-button--primary {
  border-color: #412ba4;
  background-color: #412ba4;
}
.top-box {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.top-box::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  background-color: #e4e7ed;
}
.top-tab {
  width: calc(100% - 250px);
  min-height: 44px;
}
.form-container {
  position: relative;
  width: calc(100% - 30px);
  padding: 15px 10px 0 10px;
  margin: 0 0 20px 5px;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  background: #fff;
}
.upload {
  width: 80px;
  height: 80px;
  background: #f5f5f5;
}
.name.middle {
  width: 240px;
}
.own-add-container {
  border-radius: 10px;
  padding: 15px;
  padding-bottom: 20px;
  margin-bottom: 50px;
  background: #fafafa;
}
.title-box {
  margin: 5px 0 10px 0;
  flex-wrap: wrap;
}
.title-box.margin-top {
  margin-top: 10px;
}
.title-box .unset-overflow {
  overflow: unset;
}
.title-box .border-img {
  width: calc(100% + 30px);
  height: 3px;
  margin-left: -3px;
  opacity: 0.3;
  background-image: url(../assets/images/dashed.png);
}
.title-box .title {
  font-weight: bold;
}
.el-form-item.title {
  margin-bottom: 0 !important;
}
.title-box .el-button--small,
.el-button--small.is-round {
  padding: 3px 5px;
}
::v-deep .title-box .el-button--small .el-icon-plus {
  margin-top: 1px;
}
.btn-container {
  padding-top: 0;
}
::v-deep .label-box .el-form-item__content {
  margin-left: -10px !important;
}
.right-button-container {
  right: 15px;
  bottom: 15px;
  position: absolute;
}
.page {
  text-align: center;
  padding-bottom: 20px;
}
.add-product button {
  background: #3f51b5;
}
.search {
  position: absolute;
  top: 0;
  right: 0;
  width: 240px;
}
.types-box {
  flex-wrap: wrap;
}
.margin-top .types-box {
  margin-bottom: 15px;
}
.types-box .el-form-item {
  width: 100%;
  min-height: 60px;
  margin: 15px 0;
}
.specification-box {
  position: relative;
  color: #fff;
  margin: 15px 0 0 20px;
  padding: 10px 25px 10px 15px;
  border-radius: 5px;
  background: #412ba4;
}
.specification-box .margin-left {
  margin-left: 15px;
}
.specification-box .txt {
  margin-right: 5px;
}
.specification-box .icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  color: #fff;
}
.specification-box .icon:hover {
  color: #e91e63;
  cursor: pointer;
}
::v-deep .specification-box .bigger .el-input {
  max-width: 160px !important;
}
.types-box .btn {
  margin-left: 10px;
  padding: 5px 8px;
  margin-top: 15px;
}
.types-box .btn:first-child {
  margin-left: 20px;
}
</style>