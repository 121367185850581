<!--
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-21 15:54:03
-->
<template>
  <div class="upload">
    <i class="icon el-icon-upload2" v-if="!img_src"></i>
    <input ref="fileInput" class="file-input" type="file" @change="change($event)">
    <div class="image-container flex justify-center align-center" v-if="img_src" :style="'background-image:url('+img_src+')'"></div>
  </div>
</template>

<script>
import { msg } from '../assets/js/util';
export default {
  props: ['img','zoomWidth'],
  data() {
    return {
      img_src: this.img
    }
  },
  methods: {
    change(that) {
      /**
       * 图片压缩，默认同比例压缩
       * @param {Object} path
       *   pc端传入的路径可以为相对路径，但是在移动端上必须传入的路径是照相图片储存的绝对路径
       * @param {Object} obj
       *   obj 对象 有 width， height， quality(0-1)
       * @param {Object} callback
       *   回调函数有一个参数，base64的字符串数据
       */
      function dealImage(path,obj,callback) {
        var img=new Image();
        img.src=path;
        img.onload=function() {
          var that=this;
          // 默认按比例压缩
          var w=that.width,
            h=that.height,
            scale=w/h;
          w=obj.width||w;
          h=obj.height||(w/scale);
          var quality=1;  // 默认图片质量为0.7
          //生成canvas
          var canvas=document.createElement('canvas');
          var ctx=canvas.getContext('2d');
          // 创建属性节点
          var anw=document.createAttribute("width");
          anw.nodeValue=w;
          var anh=document.createAttribute("height");
          anh.nodeValue=h;
          canvas.setAttributeNode(anw);
          canvas.setAttributeNode(anh);
          ctx.drawImage(that,0,0,w,h);
          // 图像质量
          if(obj.quality&&obj.quality<=1&&obj.quality>0) {
            quality=obj.quality;
          }
          // quality值越小，所绘制出的图像越模糊
          var base64=canvas.toDataURL('image/jpeg',quality);
          // 回调函数返回base64的值
          callback(base64);
        }
      }
      const _this=this,
        dom=this.$refs.fileInput,
        files=dom.files;
      if(files.length==0) return;
      if(!/image\/\w+/.test(files[0].type)) {
        msg('请上传图片类型的文件','error');
        return false;
      }
      const reader=new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload=function(e) {
        dealImage(e.target.result,{ width: _this.zoomWidth||800 },function(base) {
          _this.img_src=base;
          _this.$emit('selected',base);
        })
      }
    }
  }
}
</script>
<style scoped>
.upload {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-right: 15px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
}
.upload .icon {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  font-size: 20px;
  color: #9e9e9e;
  font-weight: bold;
  z-index: 1;
}
.upload .file-input {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.upload .image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
</style>