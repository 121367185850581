<!--
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-15 17:05:14
-->
<template>
  <div class="container flex align-center justify-center" :style="'background:url(' + bk_url + ')'">
    <div class="login-container">
      <div class="title">手机商城后台管理系统</div>
      <div class="login-info">{{ info }}</div>
      <div class="user">
        <input type="text" placeholder="请输入用户名" v-model="user" />
      </div>
      <div class="pass">
        <input type="password" maxlength="16" placeholder="请输入密码" v-model="pass" />
      </div>
      <button class="sub" @click="submit()">登录</button>
    </div>
  </div>
</template>

<script>
import md5 from "../assets/js/md5";
import util from "../assets/js/util";

export default {
  data() {
    return {
      info: "",
      user: "",
      pass: "",
      bk_url: "",
    };
  },

  created() {
    var _this=this;
    document.onkeydown=function() {
      var key=window.event.keyCode;
      if(key==13) {
        _this.submit();
      }
    };

    //渲染登录背景
    const bk=localStorage.getItem("bk_url");
    if(!bk) {
      const default_url=util.ossurl+"login_background/forest.jpg";
      localStorage.setItem("bk_url",default_url);
      this.bk_url=default_url;
    } else {
      this.bk_url=bk;
    }
  },

  methods: {
    submit() {
      if(!this.user) {
        return (this.info="请输入用户名...");
      }
      if(!this.pass) {
        return (this.info="请输入密码...");
      }
      const loading=util.loading();
      this.$axios
        .post("/index/login/login",{
          user: this.user,
          pass: md5(this.pass),
        })
        .then((res) => {
          loading.close();
          const data=res.data;
          if(data.code==1) {
            this.info="";
            localStorage.setItem('voice',1);
            localStorage.setItem("Authorization",data.token);
            window.history.go(0);
          } else {
            return (this.info=data.msg);
          }
        });
    },
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center 0 !important;
}
.login-container {
  width: 350px;
  color: #222;
  text-align: center;
  padding: 25px 15px;
  border-radius: 10px;
  user-select: none;
  opacity: 0.93;
  background: #ffffffcc;
}
.title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #3a3c3b;
}
.login-info {
  height: 20px;
  font-size: 14px;
  color: #ff382a;
  font-weight: bold;
  line-height: 20px;
  padding-top: 5px;
}
.user,
.pass {
  position: relative;
  padding: 10px 0;
}
.user input,
.pass input {
  width: 90%;
  padding: 12px 10px;
  font-size: 14px;
  border-radius: 3px;
  outline: none;
  letter-spacing: 1px;
  overflow: hidden;
  border: 1.3px solid #aaa;
  box-shadow: 0 3px 5px -4px rgb(0 0 0 / 40%) inset,
    -1px 0 3px -2px rgb(0 0 0 / 10%) inset;
}
.sub {
  display: inline-block;
  width: 90%;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 10px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  letter-spacing: 2px;
  cursor: pointer;
  background: #00ce52;
}
</style>