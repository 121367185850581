<template>
  <div class="container" v-if="tableData!=null">
    <div class="top-box flex align-center">
      <div class="title">回收订单<span class="tip">(鼠标移至“姓名”栏可查看发货地址)</span></div>
      <div class="btn-group flex">
        <span class="list border-right" :class="type_index==1?'active':''" @click="type_switch(1)">待收货</span>
        <span class="list border-right" :class="type_index==2?'active':''" @click="type_switch(2)">待报价</span>
        <span class="list" :class="type_index==3?'active':''" @click="type_switch(3)">报价完成</span>
      </div>
      <div class="refresh" @click="type_switch(type_index,true)"><i class="el-icon-refresh"></i></div>
    </div>
    <el-table stripe :data="tableData.filter(data => !search || data.name.includes(search)|| data.number.includes(search)||data.phone.includes(search)||data.uniqid.includes(search)||data.location.includes(search))" style="width: 100%">
      <el-table-column label="订单编号" prop="uniqid"></el-table-column>
      <el-table-column label="姓名" prop="name">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p><span class="item-name">姓名: </span>{{ scope.row.name }}</p>
            <p><span class="item-name">发货地址: </span>{{ scope.row.location }}</p>
            <div slot="reference">
              {{ scope.row.name }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="phone"></el-table-column>
      <el-table-column label="快递编号" prop="number"></el-table-column>
      <el-table-column label="下单时间" prop="time"></el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="{}">
          <el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
        </template>
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="iget_it(scope.row.uniqid)" v-if="type_index==1">确认收货</el-button>
          <el-button size="mini" type="danger" @click="give_price(scope.row.uniqid)" v-if="type_index==2">报价</el-button>
          <el-button size="mini" type="success" @click="other_info(scope.$index)" v-if="type_index==3">报价详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="回收评估报价" :visible.sync="open_dialog" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="评级" label-width="120px">
          <el-select v-model="form.how_new">
            <el-option label="准新" value="准新"></el-option>
            <el-option label="99新" value="99新"></el-option>
            <el-option label="95新" value="95新"></el-option>
            <el-option label="90新" value="90新"></el-option>
            <el-option label="85新" value="85新"></el-option>
            <el-option label="80新" value="80新"></el-option>
            <el-option label="75新" value="75新"></el-option>
            <el-option label="70新" value="70新"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格" label-width="120px">
          <el-input type="number" v-model="form.price" placeholder="请输入最终报价" autocomplete="off" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="描述" label-width="120px">
          <el-input type="textarea" v-model="form.desc" autocomplete="off" placeholder="请输入商品评估描述" maxlength="100"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="open_dialog=false">取 消</el-button>
        <el-button size="medium" type="primary" @click="give">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog class="detail-dialog" title="报价详情" :visible.sync="detail.dialog">
      <div class="list"><strong>等级评定：</strong>{{detail.how_new}}</div>
      <div class="list"><strong>最终报价：</strong>¥{{detail.price}}</div>
      <div class="list"><strong>评估描述：</strong>{{detail.desc}}</div>
    </el-dialog>

  </div>
</template>

<script>
import { msg,loading,confirm } from '../assets/js/util';

export default {
  data() {
    return {
      type_index: 0,
      tableData: null,
      search: '',
      open_dialog: false,
      form: {},
      detail: {
        dialog: false,
      }
    }
  },
  methods: {
    //订单类型切换
    type_switch(index,refresh=false) {
      if(index==this.type_index&&!refresh) return;
      this.type_index=index;
      const load=loading();
      this.$axios.post('index/recovery/render',{ type: index }).then(res => {
        load.close();
        this.tableData=res.data.orders;
        this.$parent.badges[1]=0;
      })
    },

    //确认收货
    iget_it(id) {
      confirm('提示','确定已经收到用户邮寄的物品？').then(() => {
        const load=loading();
        this.$axios.post('/index/recovery/update_order',{ uniqid: id,status: 2 }).then(res => {
          load.close();
          const { data }=res;
          if(data.code==1) {
            this.tableData=this.tableData.filter(e => e.uniqid!=id);
            msg('已确认收货');
          }
        })
      }).catch(() => { })
    },

    //报价
    give_price(id) {
      this.form.uniqid=id;
      this.open_dialog=true;
    },
    give() {
      const { form }=this;
      if(!form.how_new) return msg('请选择评级','error');
      if(!form.price) return msg('请输入最终报价','error');
      if(!form.desc) return msg('请输入商品评估描述','error');
      form.status=3;

      confirm('提示','此报价不可修改，请谨慎操作，确认继续？').then(() => {
        const load=loading();
        this.$axios.post('/index/recovery/update_order',form).then(res => {
          load.close();
          const { data }=res;
          if(data.code==1) {
            this.form={};
            this.open_dialog=false;
            this.tableData=this.tableData.filter(e => e.uniqid!=form.uniqid);
            msg('报价成功');
          }
        })
      }).catch(() => { })
    },

    //报价详情
    other_info(index) {
      const { how_new,price,desc }=this.tableData[index];
      this.detail={ dialog: true,how_new,price,desc };
    }
  },

  created() {
    this.type_switch(1);
  }
}
</script>

<style scoped>
.container {
  padding: 0 10px 100px 10px;
}

::v-deep .el-input__inner {
  max-width: 260px;
  padding: 17px 12px;
}
::v-deep .el-textarea__inner {
  max-width: 360px;
  padding: 10px 12px;
}
::v-deep .el-dialog {
  max-width: 600px;
  width: auto;
}

::v-deep .el-dialog__body {
  padding-bottom: 10px;
}

.top-box .title {
  font-size: 16px;
  color: #393d49;
  font-weight: bold;
  letter-spacing: 1px;
}

.top-box .title .tip {
  font-size: 12px;
  color: #9e9e9e;
  font-weight: normal;
  letter-spacing: 0.3px;
}

.top-box .btn-group {
  border: 1px solid #393d49;
  border-radius: 5px;
  margin-left: 30px;
}

.top-box .btn-group .list {
  display: inline-block;
  font-size: 12px;
  color: #393d49;
  padding: 5px 10px;
  transition: all 0.2s;
  cursor: pointer;
}

.top-box .btn-group .list.border-right {
  border-right: 1px solid #393d49;
}

.top-box .btn-group .list.active {
  color: #fff;
  background: #393d49;
}

.top-box .refresh {
  color: #fff;
  padding: 3px 6px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 30px;
  border-radius: 3px;
  background: #393d49;
}

::v-deep .detail-dialog .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .detail-dialog .list {
  font-size: 13px;
  letter-spacing: 0.8px;
  padding-bottom: 10px;
}
</style>