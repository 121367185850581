<template>
  <div class="container" v-if="products !== null">
    <div class="top-box">
      <el-tabs v-model="activeName" :before-leave="switch_brand">
        <el-tab-pane label="苹果" name="苹果"></el-tab-pane>
        <el-tab-pane label="华为" name="华为"></el-tab-pane>
        <el-tab-pane label="小米" name="小米"></el-tab-pane>
        <el-tab-pane label="笔记本" name="笔记本"></el-tab-pane>
        <el-tab-pane label="荣耀" name="荣耀"></el-tab-pane>
        <el-tab-pane label="vivo" name="vivo"></el-tab-pane>
        <el-tab-pane label="oppo" name="oppo"></el-tab-pane>
        <el-tab-pane label="保值" name="其它"></el-tab-pane>
      </el-tabs>
      <el-input class="search" v-model="search_inp" placeholder="按回车搜索" @keyup.enter.native="search()"></el-input>
    </div>

    <div class="form-container" label-width="80px" v-for="(item, p_index) in products" :key="item.uniqid ? item.uniqid : item.key">
      <el-form>
        <el-form-item label="商品图片">
          <img-uploader v-for="(img, index) in item.imgs" :key="index" :img="img" @selected="img_choosed($event, p_index, index)"></img-uploader>
        </el-form-item>

        <el-form-item label="商品名称">
          <el-input class="middle" placeholder="请输入商品名称" v-model="item.name"></el-input>
        </el-form-item>

        <div class="check-box flex">
          <el-form-item class="inline" label="基础价格">
            <el-input type="number" placeholder="¥" v-model="item.price"></el-input>
          </el-form-item>

          <el-form-item class="inline margin flex align-center checkbox" label="商品包含多个类别">
            <el-checkbox-group class="flex align-center" v-model="item.checkList" :max="2">
              <el-checkbox label="机身颜色" @change="specification_change($event,p_index)"></el-checkbox>
              <el-checkbox label="存储空间" @change="specification_change($event,p_index)"></el-checkbox>
              <el-checkbox label="规格" @change="specification_change($event,p_index)"></el-checkbox>
              <el-checkbox label="大小" @change="specification_change($event,p_index)"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>

        <div :ref="'specification'+p_index" class="types-box flex align-center" v-for="(types,t_index) in item.specification" :key="t_index">
          <el-form-item class="inline flex" :label="types.type">
            <div class="specification-box flex" v-for="(detail,d_index) in types.item" :key="d_index">
              <div class="bigger flex align-center">
                <span class="txt">{{types.type}}</span>
                <el-input class="need_focus" type="text" :placeholder="types.type" maxlength="20" v-model="detail.detail"></el-input>
              </div>
              <div class="flex align-center margin-left">
                <span class="txt">加价</span>
                <el-input type="number" placeholder="¥" v-model="detail.price_add"></el-input>
              </div>
              <i class="icon el-icon-circle-close" @click="specification_delete(p_index,t_index,d_index)"></i>
            </div>
            <el-button class="btn" size="small" icon="el-icon-plus" @click="specification_add(p_index,t_index)"></el-button>
          </el-form-item>
        </div>

        <el-form-item class="flex align-center label-box" label="描述标签">
          <el-tag type="success" :key="tag" v-for="tag in item.tags" closable :disable-transitions="false" @close="handleClose(tag, p_index)">{{ tag }}</el-tag>
          <el-input class="input-new-tag" v-if="item.inputVisible" v-model="item.inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm(p_index)" @blur="handleInputConfirm(p_index)">
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput(p_index)">添加新标签</el-button>
        </el-form-item>

        <el-form-item label="商品描述">
          <el-input class="large" placeholder="请输入商品描述" maxlength="100" v-model="item.desc"></el-input>
        </el-form-item>
      </el-form>

      <div class="right-button-container">
        <button class="small" style="background:#de594f;" @click="product_remove(p_index)">删除</button>
        <button class="small" style="background:#67c23a;margin-left:10px;" @click="product_save(p_index)">保存</button>
      </div>
    </div>
    <div class="page" v-if="page_count != null && !products.length == 0">
      <el-pagination background layout="prev, pager, next" :page-count="page_count" :current-page="cur_page" @current-change="page_change"></el-pagination>
    </div>
    <div class="btn-container add-product" @click="add_product" v-if="cur_page == page_count">
      <button class="normal">添加新商品</button>
    </div>
  </div>
</template>

<script>
import imgUploader from '../components/ImgUploader.vue';
import util from '../assets/js/util';

export default {
  components: {
    imgUploader
  },
  data() {
    return {
      activeName: '苹果',
      search_inp: '',
      products: null,
      page_count: null,
      cur_page: null
    };
  },
  methods: {
    //选中图片
    img_choosed(src,p_index,i_index) {
      this.products[p_index].imgs[i_index]=src;
    },

    //添加商品
    add_product: util.throttle(function() {
      this.products.push({
        key: Date.now(),
        imgs: ['','','','','',''],
        name: '',
        checkList: ['机身颜色','存储空间'],
        checkList_back: ['机身颜色','存储空间'],
        tags: [],
        is_new: '1',
        price: '',
        specification: [
          { type: '机身颜色',item: [{ detail: '',price_add: 0 }] },
          { type: '存储空间',item: [{ detail: '',price_add: 0 }] }
        ],
        inputVisible: false,
        desc: ''
      })
    }),

    //商品分类复选框操作
    specification_change(bool,p_index) {
      let product=this.products[p_index];
      if(bool) {
        product.checkList_back=product.checkList;
        product.specification.push({
          type: product.checkList[product.checkList.length-1],
          item: [{ detail: '',price_add: 0 }]
        });
        this.$nextTick(() => {
          this.$refs['specification'+p_index][product.checkList.length-1].querySelector('.need_focus input').focus()
        })
      } else {
        util.confirm('提示','取消该分类后分类内容也将被删除，确定继续取消？').then(() => {
          product.checkList_back=product.checkList;
          product.specification=product.specification.filter(s => product.checkList.includes(s.type));
        }).catch(() => {
          product.checkList=product.checkList_back;
        })
      }
    },

    //添加规格
    specification_add(p_index,t_index) {
      const product=this.products[p_index];
      if(product.specification[t_index].item.length>=10) return util.msg('最多添加10个分类详情','error');
      product.specification[t_index].item.push({
        detail: '',
        price_add: 0
      })
      this.$nextTick(() => {
        const inps=this.$refs['specification'+p_index][t_index].querySelectorAll('.need_focus input');
        inps[inps.length-1].focus();
      })
    },

    //删除规格
    specification_delete(p,t,d) {
      let product=this.products[p];
      if(product.specification[t].item.length<=1) return util.msg('最少保留一个分类详情,或直接取消勾选该分类','error');
      util.confirm('提示','确定删除该分类详情').then(() => {
        product.specification[t].item=product.specification[t].item.filter((e,i) => i!=d);
      }).catch(() => { })
    },

    //标签操作
    handleClose(tag,index) {
      this.products[index].tags.splice(this.products[index].tags.indexOf(tag),1);
    },
    showInput(index) {
      this.products.forEach(e => {
        e.inputVisible=false;
      })
      this.products[index].inputVisible=true;
      this.$nextTick(() => {
        this.$refs.saveTagInput[0].$refs.input.focus();
      });
    },
    handleInputConfirm(index) {
      let inputValue=this.products[index].inputValue;
      if(inputValue) {
        if(this.products[index].tags.indexOf(inputValue)>=0) {
          util.msg('重复添加','error');
        } else {
          this.products[index].tags.push(inputValue);
        }
      }
      this.products[index].inputVisible=false;
      this.products[index].inputValue='';
    },

    //保存商品信息
    product_save: util.throttle(function(index) {
      const product=Object.assign({},this.products[index]);
      product.imgs=product.imgs.filter(e => e.length>0);
      if(product.imgs.length==0) return util.msg('至少上传一张图片哦','error');
      if(!product.name) return util.msg('请输入商品名称','error');
      if(!product.price) return util.msg('请输入基础价格','error');
      if(!product.desc) return util.msg('请输入商品描述','error');

      product.price_top=product.price;
      for(const specification of product.specification) {
        for(const item of specification.item) {
          if(!item.detail||item.price_add==='') return util.msg('存在未填写的商品分类详情','error');
          product.price_top=Math.max(product.price_top,util.exact(product.price,item.price_add));
        }
      }

      const loading=util.loading();
      product.brand=this.activeName;
      product.specification=JSON.stringify(product.specification);
      for(let i=0;i<product.imgs.length;i++) {
        if(/^http/.test(product.imgs[i])) product.imgs[i]='';
        else product.imgs[i]=product.imgs[i].replace(/image\/.+;base64/,'image/jpg;base64');
      }

      this.$axios.post('/index/product/save',product).then(res => {
        loading.close();
        const { data }=res;
        if(data.code==1) {
          util.msg(data.msg);
          if(data.uniqid) this.products[index].uniqid=data.uniqid;
        }
      })
    }),

    //删除商品
    product_remove: util.throttle(function(index) {
      const _this=this;
      function deal() {
        _this.products=_this.products.filter((e,i) => i!=index);
        util.msg('删除成功');
      }

      util.confirm('提示','确定删除该商品？').then(() => {
        if(!this.products[index].uniqid) return deal();
        const uniqid=this.products[index].uniqid,
          loading=util.loading();
        this.$axios.post('/index/product/remove',{ uniqid }).then(res => {
          loading.close();
          const { data }=res;
          if(data.code==1) {
            deal();
          }
        })
      }).catch(() => { })
    }),

    //商品渲染
    brand_load(brand,page,need_count=false) {
      const loading=util.loading();
      if(need_count) this.page_count=null;
      let send_data={
        brand,
        page,
        need_count,
        is_new: 1,
        search: this.search_inp
      };
      this.$axios.post('/index/product/render',send_data).then(res => {
        loading.close();
        const { data }=res;
        this.cur_page=page;
        this.products=data.products;
        if(need_count) this.page_count=data.page_count;
      })
    },

    //搜索商品
    search() {
      this.brand_load(this.activeName,1,true);
    },

    //切换品牌
    switch_brand(c,o) {
      this.search_inp='';
      if(c==o) return false;
      this.brand_load(c,1,true);
    },

    //切换分页
    page_change(e) {
      this.brand_load(this.activeName,e)
    }
  },

  created() {
    this.brand_load(this.activeName,1,true);
  }
}
</script>

<style scoped>
::v-deep .el-tabs__active-bar {
  background-color: #409eff;
}

::v-deep .el-tabs__item.is-active {
  color: #409eff;
}

::v-deep .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
::v-deep .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
::v-deep .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px;
}

::v-deep .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep .check-box .el-checkbox__input {
  vertical-align: unset;
}
::v-deep .check-box .el-form-item__content,
::v-deep .el-form-item.inline,
::v-deep .el-checkbox__input,
::v-deep .el-checkbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

::v-deep .types-box .el-form-item__content {
  flex-wrap: wrap;
  align-items: center;
  margin: -15px 0 0 -20px !important;
}

::v-deep .el-checkbox {
  align-items: center;
}

::v-deep .el-form-item__label {
  min-width: 70px;
}

.top-box {
  position: relative;
  margin-bottom: 15px;
}

.form-container {
  position: relative;
  padding: 15px 10px 0 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #f5f5f5;
}

.btn-container {
  padding-top: 0;
}

::v-deep .label-box .el-form-item__content {
  margin-left: -10px !important;
}

.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  letter-spacing: 0.5px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.right-button-container {
  right: 15px;
  bottom: 15px;
  position: absolute;
}

.page {
  text-align: center;
  padding-bottom: 20px;
}

.add-product button {
  background: #3f51b5;
}

.search {
  position: absolute;
  top: 0;
  right: 0;
  width: 240px;
}

.types-box {
  margin-bottom: 15px;
}

.types-box .el-form-item {
  margin-bottom: 0;
}

.specification-box {
  position: relative;
  margin: 15px 0 0 20px;
  padding: 10px 25px 10px 15px;
  border-radius: 5px;
  background: #fff;
}

.specification-box .margin-left {
  margin-left: 15px;
}

.specification-box .txt {
  margin-right: 5px;
}

.specification-box .icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  color: #9e9e9e;
}
.specification-box .icon:hover {
  color: #e91e63;
  cursor: pointer;
}

::v-deep .specification-box .bigger .el-input {
  max-width: 160px !important;
}

.types-box .btn {
  margin-left: 20px;
  margin-top: 15px;
}
</style>