<template>
  <div class="online-status flex align-center">
    <el-popover placement="bottom" v-model="visible">
      <div slot="reference">
        <div class="flex align-center" :class="onlineStatus!==1?'err':''" v-if="onlineStatus!==null">
          <span class="status-circle"></span>
          <span class="status-text">{{onlineStatus==1?'在线':'离线'}}</span>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn flex align-center" v-if="onlineStatus===1" @click="breakSocket">
          <i class="icon red el-icon-error"></i>断开链接
        </button>
        <button class="btn flex align-center" @click="refreshSocket">
          <i class="icon green el-icon-refresh-right"></i>重新连接
        </button>
      </div>
    </el-popover>

  </div>
</template>

<script>
export default {
  data() {
    return {
      onlineStatus: null,
      visible: false,
    }
  },
  methods: {
    breakSocket() {
      this.visible=false;
      this.$emit('breakSocket');
    },
    refreshSocket() {
      this.visible=false;
      this.$emit('refreshSocket');
    }
  }
}
</script>

<style scoped>
.online-status {
  padding: 5px;
  cursor: pointer;
}
span {
  display: inline-block;
}
.status-circle {
  width: 12px;
  height: 12px;
  background: #4caf50;
  border-radius: 12px;
}
.err .status-circle {
  background: #f00014;
}
.status-text {
  font-size: 12px;
  color: #4caf50;
  font-weight: bold;
  margin-left: 6px;
}
.err .status-text {
  color: #f00014;
}
.btn-container {
  padding: 0;
}
.btn-container .btn {
  font-size: 13px;
  color: #3d3d3d;
  letter-spacing: 1px;
  padding: 5px;
  margin: 5px 0;
  background: none;
}
.btn-container .icon {
  margin-right: 3px;
}
.btn-container .icon.red {
  color: #f00014;
}
.btn-container .icon.green {
  color: #4caf50;
  font-weight: bold;
}
</style>