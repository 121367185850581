<!--
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-25 16:33:19
-->
<template>
  <div class="container">
    <el-form ref="form" label-width="80px">
      <el-form-item label="新密码">
        <el-input class="middle" placeholder="请输入新密码" type="password" maxlength="16" v-model="p1"></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input class="middle" placeholder="请再次输入新密码" type="password" maxlength="16" v-model="p2"></el-input>
      </el-form-item>
      <button class="btn normal" @click="sub">修改密码</button>
    </el-form>
  </div>
</template>

<script>
import md5 from '../assets/js/md5';
import { prompt,msg,loading } from "../assets/js/util";

export default {
  data() {
    return {
      p1: '',
      p2: ''
    }
  },
  methods: {
    sub() {
      const { p1,p2 }=this,
        data={ p1: md5(p1),p2: md5(p2) };
      if(!p1) return msg('请输入新密码','error');
      if(!p2) return msg('请输入确认密码','error');
      if(p1!==p2) return msg('两次输入的密码不一致','error');

      prompt('提示','该操作需要输入密码',{ inputType: 'password' }).then(({ value }) => {
        data.pass=md5(value);

        const load=loading();
        this.$axios.post('/index/repass/change',data).then(res => {
          const { data }=res;
          if(data.code==1) {
            msg('修改成功');
            this.p1='';
            this.p2='';
          }
          load.close();
        })
      })
    }
  }
}
</script>

<style scoped>
.container {
  padding: 10px 0;
}
.btn {
  margin-left: 80px;
  background: #3f51b5;
}
</style>