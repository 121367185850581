<!--
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2022-02-20 23:33:56
-->
<template>
  <div class="container">
    <el-form :model="form">
      <el-form-item label="姓名" label-width="120px">
        <el-input ref="need_select" v-model="form.name" autocomplete="off" placeholder="请输入姓名" maxlength="16" @blur="name"></el-input>
      </el-form-item>
      <el-form-item label="手机号" label-width="120px">
        <el-input type="number" v-model="form.phone" autocomplete="off" placeholder="请输入手机号" @input="form.phone=form.phone.length>11?form.phone.slice(0,11):form.phone" @blur="phone"></el-input>
      </el-form-item>
      <el-form-item label="微信号" label-width="120px">
        <el-input v-model="form.wechat" autocomplete="off" placeholder="请输入微信号" maxlength="50" @blur="wechat"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { msg,loading } from '../assets/js/util';

export default {
  data() {
    return {
      form: {}
    }
  },
  methods: {
    name() {
      const name=this.form.name;
      if(!name) return msg('请输入姓名','error');
      this.save({ name });
    },
    phone() {
      const phone=this.form.phone;
      if(!phone) return msg('请输入手机号','error');
      if(!/^1[^012Ï]\d{9}$/.test(phone)) return msg('手机号格式不正确','error');
      this.save({ phone });
    },
    wechat() {
      const wechat=this.form.wechat;
      if(!wechat) return msg('请输入微信号','error');
      this.save({ wechat });
    },
    save(data) {
      const load=loading();
      this.$axios.post('/index/dperson/update',data).then(res => {
        load.close();
        const { data }=res;
        if(data.code==1) msg('修改成功');
      })
    }
  },
  created() {
    const load=loading();
    this.$axios.post('/index/dperson/render').then(res => {
      load.close();
      this.form=res.data.dperson;
    })
  }
}
</script>

<style scoped>
.container {
  margin-top: 20px;
}
::v-deep input {
  max-width: 300px;
}
</style>